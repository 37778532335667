import React from "react";

function Button({ text, link = "#" }) {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="flex justify-center items-center group transition-all hover:opacity-80"
    >
      <span className="h-14 bg-primary text-black px-12 flex justify-center items-center rounded-full">
        {text}
      </span>
      <span className="bg-btn-gradient w-16 h-14 -ml-8 transition-all group-hover:w-24"></span>
      <span className="-ml-4 h-16 w-16 bg-primary rounded-full inline-flex justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1.5em"
          viewBox="0 0 448 512"
          fill="#000"
        >
          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
        </svg>
      </span>
    </a>
  );
}

export default Button;
