import logo from "./assets/images/logo.png";
import footerLogo from "./assets/images/footer-logo.png";
import title1 from "./assets/images/title-1.png";
import title2 from "./assets/images/title-2.png";
import bg1 from "./assets/images/bg-1.png";
import bg2 from "./assets/images/bg-2.png";
import aboutTitle from "./assets/images/about.png";
import aboutLogo from "./assets/images/about-logo.png";
import ecos1 from "./assets/images/ecos-1.png";
import ecos2 from "./assets/images/ecos-2.png";
import ecos3 from "./assets/images/ecos-3.png";
import ecos4 from "./assets/images/ecos-4.png";
import tokenomics from "./assets/images/tokenomics.png";
import rocket from "./assets/images/rocket.png";
import ball from "./assets/images/ball.png";
import roadmapLine from "./assets/images/roadmap-line.png";
import join from "./assets/images/join.png";
import tg from "./assets/images/tg.png";
import tw from "./assets/images/tw.png";
import dt from "./assets/images/dt.png";
import sc from "./assets/images/sc.png";
import Button from "./components/button";

import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="App overflow-hidden">
      <header className="container mx-auto px-5">
        <div className="flex flex-col gap-5 lg:flex-row py-5 justify-between items-center lg:py-8">
          <div className="max-w-[120px] lg:max-w-[150px]">
            <img src={logo} alt="logo" />
          </div>
          <ul className="flex items-center gap-5 lg:gap-8 xl:gap-10 text-sm">
            <li>
              <a
                href="/"
                className="pb-1 border-b-2 border-transparent transition-all hover:border-primary hover:text-primary"
              >
                HOME
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="pb-1 border-b-2 border-transparent transition-all hover:border-primary hover:text-primary"
              >
                ABOUT
              </a>
            </li>
            <li>
              <a
                href="#ecosystem"
                className="pb-1 border-b-2 border-transparent transition-all hover:border-primary hover:text-primary"
              >
                ECOSYSTEM
              </a>
            </li>
            <li>
              <a
                href="#tokenomics"
                className="pb-1 border-b-2 border-transparent transition-all hover:border-primary hover:text-primary"
              >
                TOKENOMICS
              </a>
            </li>
          </ul>
          <div className="flex items-center gap-3 lg:gap-5 xl:gap-8">
            <a
              href="https://t.me/GrokChainTG"
              target="_blank"
              rel="noopener noreferrer"
              className="border-transparent border-[1px] rounded-full px-8 py-2 transition-all hover:text-primary"
            >
              Telegram
            </a>
            <a
              href="https://twitter.com/GrokChainX"
              target="_blank"
              rel="noopener noreferrer"
              className="border-primary border-[1px] rounded-full px-8 py-2 transition-all hover:bg-primary hover:text-black"
            >
              Twitter
            </a>
          </div>
        </div>
      </header>

      <main className="relative">
        <section className="container relative mx-auto px-5 text-center py-10">
          <div className="absolute hidden lg:block max-w-sm -left-44 top-2/3">
            <img src={bg1} alt="bg1" />
          </div>
          <div className="absolute hidden lg:block max-w-lg -right-40 top-1/2">
            <img src={bg2} alt="bg2" />
          </div>

          <h1 className="text-2xl lg:text-3xl font-mont">GrokChain</h1>

          <div className="w-full lg:w-3/4 mx-auto mt-3 lg:mt-5">
            <img src={title1} alt="title-1" />
            <img src={title2} alt="title-2" />
          </div>
          <p className="max-w-4xl mx-auto mt-5 lg:mt-8 font-mont">
            The epitome of intelligent connectivity, seamlessly intertwines
            innovation and AI, forging a dynamic chain that propels you into the
            future of intelligent solutions.
          </p>

          <div className="flex justify-center mt-12 lg:mt-16">
            <Button text="Open dApp" />
          </div>

          <div className="flex justify-center flex-col items-center md:flex-row gap-8 lg:gap-20 px-10 mt-24">
            <div className="bg-box box-shadow-custom rounded-xl h-60 w-60 flex flex-col justify-center items-center text-center gap-3 relative">
              <h4 className="text-4xl text-primary font-bold">20+</h4>
              <span className="text-xl">Wallet Types</span>
              <span className="absolute right-2 top-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                >
                  <g clip-path="url(#clip0_209_192)">
                    <path
                      d="M16.6924 0C13.391 0 10.1637 0.978992 7.41861 2.81318C4.67356 4.64736 2.53405 7.25436 1.27064 10.3045C0.00723258 13.3546 -0.323333 16.7109 0.320747 19.9489C0.964827 23.1869 2.55462 26.1612 4.8891 28.4957C7.22357 30.8302 10.1979 32.42 13.4359 33.0641C16.6739 33.7081 20.0302 33.3776 23.0803 32.1142C26.1305 30.8508 28.7374 28.7113 30.5716 25.9662C32.4058 23.2212 33.3848 19.9938 33.3848 16.6924C33.3848 12.2653 31.6262 8.01952 28.4957 4.88909C25.3653 1.75866 21.1195 0 16.6924 0V0ZM26.1793 12.6654L15.7048 24.5448C15.5357 24.7352 15.3308 24.8904 15.1016 25.0014C14.8724 25.1124 14.6236 25.177 14.3694 25.1916H14.2442C13.7697 25.1945 13.3112 25.021 12.9575 24.7048L7.35162 19.7318C6.96609 19.3896 6.73229 18.9083 6.70163 18.3938C6.67098 17.8792 6.84599 17.3735 7.18817 16.988C7.53035 16.6025 8.01166 16.3687 8.52623 16.338C9.04079 16.3074 9.54646 16.4824 9.93199 16.8245L14.1051 20.5038L23.2929 10.0711C23.6341 9.68463 24.1149 9.44958 24.6295 9.41762C25.144 9.38566 25.6502 9.55942 26.0367 9.90068C26.4231 10.2419 26.6582 10.7227 26.6901 11.2373C26.7221 11.7519 26.5483 12.258 26.2071 12.6445L26.1793 12.6654Z"
                      fill="#FEFF05"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_209_192">
                      <rect width="33.3848" height="33.3848" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
            <div className="bg-box box-shadow-custom rounded-xl h-60 w-60 flex flex-col justify-center items-center text-center gap-3 relative">
              <h4 className="text-4xl text-primary font-bold">2000+</h4>
              <span className="text-xl">Users</span>
              <span className="absolute right-2 top-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                >
                  <g clip-path="url(#clip0_209_192)">
                    <path
                      d="M16.6924 0C13.391 0 10.1637 0.978992 7.41861 2.81318C4.67356 4.64736 2.53405 7.25436 1.27064 10.3045C0.00723258 13.3546 -0.323333 16.7109 0.320747 19.9489C0.964827 23.1869 2.55462 26.1612 4.8891 28.4957C7.22357 30.8302 10.1979 32.42 13.4359 33.0641C16.6739 33.7081 20.0302 33.3776 23.0803 32.1142C26.1305 30.8508 28.7374 28.7113 30.5716 25.9662C32.4058 23.2212 33.3848 19.9938 33.3848 16.6924C33.3848 12.2653 31.6262 8.01952 28.4957 4.88909C25.3653 1.75866 21.1195 0 16.6924 0V0ZM26.1793 12.6654L15.7048 24.5448C15.5357 24.7352 15.3308 24.8904 15.1016 25.0014C14.8724 25.1124 14.6236 25.177 14.3694 25.1916H14.2442C13.7697 25.1945 13.3112 25.021 12.9575 24.7048L7.35162 19.7318C6.96609 19.3896 6.73229 18.9083 6.70163 18.3938C6.67098 17.8792 6.84599 17.3735 7.18817 16.988C7.53035 16.6025 8.01166 16.3687 8.52623 16.338C9.04079 16.3074 9.54646 16.4824 9.93199 16.8245L14.1051 20.5038L23.2929 10.0711C23.6341 9.68463 24.1149 9.44958 24.6295 9.41762C25.144 9.38566 25.6502 9.55942 26.0367 9.90068C26.4231 10.2419 26.6582 10.7227 26.6901 11.2373C26.7221 11.7519 26.5483 12.258 26.2071 12.6445L26.1793 12.6654Z"
                      fill="#FEFF05"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_209_192">
                      <rect width="33.3848" height="33.3848" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
            <div className="bg-box box-shadow-custom rounded-xl h-60 w-60 flex flex-col justify-center items-center text-center gap-3 relative">
              <h4 className="text-4xl text-primary font-bold">10M+</h4>
              <span className="text-xl">Transactions</span>
              <span className="absolute right-2 top-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                >
                  <g clip-path="url(#clip0_209_192)">
                    <path
                      d="M16.6924 0C13.391 0 10.1637 0.978992 7.41861 2.81318C4.67356 4.64736 2.53405 7.25436 1.27064 10.3045C0.00723258 13.3546 -0.323333 16.7109 0.320747 19.9489C0.964827 23.1869 2.55462 26.1612 4.8891 28.4957C7.22357 30.8302 10.1979 32.42 13.4359 33.0641C16.6739 33.7081 20.0302 33.3776 23.0803 32.1142C26.1305 30.8508 28.7374 28.7113 30.5716 25.9662C32.4058 23.2212 33.3848 19.9938 33.3848 16.6924C33.3848 12.2653 31.6262 8.01952 28.4957 4.88909C25.3653 1.75866 21.1195 0 16.6924 0V0ZM26.1793 12.6654L15.7048 24.5448C15.5357 24.7352 15.3308 24.8904 15.1016 25.0014C14.8724 25.1124 14.6236 25.177 14.3694 25.1916H14.2442C13.7697 25.1945 13.3112 25.021 12.9575 24.7048L7.35162 19.7318C6.96609 19.3896 6.73229 18.9083 6.70163 18.3938C6.67098 17.8792 6.84599 17.3735 7.18817 16.988C7.53035 16.6025 8.01166 16.3687 8.52623 16.338C9.04079 16.3074 9.54646 16.4824 9.93199 16.8245L14.1051 20.5038L23.2929 10.0711C23.6341 9.68463 24.1149 9.44958 24.6295 9.41762C25.144 9.38566 25.6502 9.55942 26.0367 9.90068C26.4231 10.2419 26.6582 10.7227 26.6901 11.2373C26.7221 11.7519 26.5483 12.258 26.2071 12.6445L26.1793 12.6654Z"
                      fill="#FEFF05"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_209_192">
                      <rect width="33.3848" height="33.3848" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </div>
          </div>
        </section>

        <section
          id="about"
          data-aos="fade-up"
          className="container flex flex-col lg:flex-row gap-10 items-center justify-between mx-auto px-5 py-20"
        >
          <div className="max-w-xs lg:max-w-xl">
            <img src={aboutLogo} alt="about-logo" />
          </div>
          <div className="max-w-full lg:max-w-xl">
            <div className="">
              <img src={aboutTitle} alt="about-title" />
            </div>
            <div className="flex items-center gap-5 mt-5">
              <span className="h-[1px] w-24 bg-primary"></span>
              <span>GROK CHAIN</span>
            </div>
            <h3 className="text-2xl lg:text-4xl font-mont font-semibold mt-8">
              GrokChain: <br /> Unleashing Intelligent{" "}
              <span className="text-primary">Connectivity</span>.
            </h3>
            <p className="text-text mt-8 text-sm lg:text-base">
              GrokChain redefines the landscape of intelligent connectivity,
              liberating possibilities beyond conventional constraints. Immerse
              yourself in a captivating network where innovation knows no
              bounds, exploring realms from cutting-edge technology to
              futuristic collaborations. Connect, innovate, and thrive in the
              liberating embrace of GrokChain's intelligent ecosystem.
            </p>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-8 inline-flex bg-black px-12 py-3 rounded-full border-primary border-[1px] transition-all hover:bg-primary hover:text-black"
            >
              OPEN DAPP
            </a>
          </div>
        </section>

        <section
          id="ecosystem"
          className="py-16 pb-52 bg-[#242424] text-center border-b-4 border-b-[#484848]"
        >
          <div className="container mx-auto px-5">
            <h4 className="text-lg uppercase">GrokChain Ecosystem</h4>
            <h3 className="text-3xl lg:text-4xl font-semibold max-w-5xl mx-auto leading-tight mt-10">
              Embark on the GrokChain Odyssey: <br /> Navigating the{" "}
              <span className="text-primary">Intelligent Ecosystem</span>
            </h3>
          </div>
        </section>

        <section className="container mx-auto px-5 -mt-32">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-10">
            <div
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="100"
              className="bg-box rounded-xl overflow-hidden"
            >
              <div className="bg-[url('./assets/images/bg-box.png')] bg-cover p-4 pl-6">
                <div className="w-16">
                  <img src={ecos1} alt="ecos1" />
                </div>
                <div className="mt-6 ml-4">
                  <h4>GrokChain DEX</h4>
                  <p className="text-text text-sm mt-3 leading-relaxed">
                    Step into the future of decentralized exchanges with
                    GrokChain DEX, where transactions flow seamlessly and
                    securely. Experience a revolutionary platform that redefines
                    fluidity, empowering users with the freedom to trade
                    intelligently and effortlessly in a decentralized landscape.
                  </p>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="200"
              className="bg-box hover:bg-box-hover rounded-xl overflow-hidden"
            >
              <div className="bg-[url('./assets/images/bg-box.png')] bg-cover p-4 pl-6">
                <div className="w-16">
                  <img src={ecos2} alt="ecos2" />
                </div>
                <div className="mt-6 ml-4">
                  <h4>GrokChain Staking</h4>
                  <p className="text-text text-sm mt-3 leading-relaxed">
                    Empower your journey within the GrokChain ecosystem through
                    Staking. Unleash the potential of your holdings, actively
                    participate in the network's growth, and reap the rewards of
                    your commitment. GrokChain Staking is not just an
                    investment; it's a dynamic engagement that propels you into
                    the heart of intelligent connectivity.
                  </p>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="300"
              className="bg-box rounded-xl overflow-hidden"
            >
              <div className="bg-[url('./assets/images/bg-box.png')] bg-cover p-4 pl-6">
                <div className="w-16">
                  <img src={ecos3} alt="ecos3" />
                </div>
                <div className="mt-6 ml-4">
                  <h4>GrokChain Bridge</h4>
                  <p className="text-text text-sm mt-3 leading-relaxed">
                    Explore new horizons with GrokChain Bridge, a gateway to
                    connect diverse domains within the intelligent ecosystem.
                    Seamlessly traverse between different chains, unlocking the
                    potential for collaboration, innovation, and a unified
                    future. GrokChain Bridge is your key to breaking down
                    barriers and forging interconnected pathways.
                  </p>
                </div>
              </div>
            </div>

            <div
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="400"
              className="bg-box rounded-xl overflow-hidden"
            >
              <div className="bg-[url('./assets/images/bg-box.png')] bg-cover p-4 pl-6">
                <div className="w-16">
                  <img src={ecos4} alt="ecos4" />
                </div>
                <div className="mt-6 ml-4">
                  <h4>GrokChain Explorer</h4>
                  <p className="text-text text-sm mt-3 leading-relaxed">
                    Dive into the depths of the GrokChain ecosystem with the
                    Explorer feature. Unveil intelligent insights, real-time
                    data, and the heartbeat of the network. GrokChain Explorer
                    is your window into the inner workings of the intelligent
                    ecosystem, providing the information you need to make
                    informed decisions and navigate the landscape with
                    confidence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="tokenomics"
          data-aos="fade-up"
          className="container mx-auto px-5 py-10 pb-20 relative"
        >
          <div className="max-w-5xl relative">
            <div className="absolute max-w-[170px] -right-20">
              <img src={ball} alt="ball" />
            </div>
            <img src={tokenomics} alt="tokenomics" />
          </div>
          <div className="flex flex-col-reverse gap-5 lg:flex-row justify-between items-center lg:px-0 xl:px-28 2xl:px-32 mt-10 lg:-mt-16">
            <div className="flex flex-col items-center">
              <ul className="min-w-[220px] flex flex-col gap-3">
                <li className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span>Team:</span>
                  </div>
                  <span className="text-primary">10%</span>
                </li>
                <li className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span>Airdrop:</span>
                  </div>
                  <span className="text-primary">8%</span>
                </li>
                <li className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span>Staking reward:</span>
                  </div>
                  <span className="text-primary">8%</span>
                </li>
                <li className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span>Foundation:</span>
                  </div>
                  <span className="text-primary">15%</span>
                </li>
                <li className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span>Marketing:</span>
                  </div>
                  <span className="text-primary">5%</span>
                </li>
                <li className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span>Advisors:</span>
                  </div>
                  <span className="text-primary">8%</span>
                </li>
                <li className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span>DAO fund:</span>
                  </div>
                  <span className="text-primary">15%</span>
                </li>
                <li className="flex justify-between">
                  <div className="flex items-center gap-3">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span>Backups:</span>
                  </div>
                  <span className="text-primary">11%</span>
                </li>
              </ul>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-8 inline-flex bg-black px-10 py-3 rounded-full border-primary border-[1px] transition-all hover:bg-primary hover:text-black"
              >
                BUY NOW
              </a>
            </div>

            <div className="max-w-lg lg:max-w-xl rocket-animate">
              <img src={rocket} alt="rocket" />
            </div>
          </div>
        </section>

        <section className="py-8 border-t-[1px] border-b-[1px] border-[#c3c3c3]">
          <div className="container mx-auto gap-5 px-5 flex items-center justify-around">
            <span>GROK CHAIN</span>
            <span>GROK CHAIN</span>
            <span className="hidden md:block">GROK CHAIN</span>
            <span className="hidden lg:block">GROK CHAIN</span>
            <span className="hidden lg:block">GROK CHAIN</span>
            <span className="hidden xl:block">GROK CHAIN</span>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="arrow-animate cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="20"
                viewBox="0 0 29 20"
                fill="none"
              >
                <path
                  d="M19.125 19.1248C18.6469 19.1248 18.3281 18.9654 18.0094 18.6467C17.3719 18.0092 17.3719 17.0529 18.0094 16.4154L25.978 8.44675C26.6155 7.80925 27.5718 7.80925 28.2093 8.44675C28.8468 9.08424 28.8468 10.0405 28.2093 10.678L20.2406 18.6467C19.9219 18.9654 19.6031 19.1248 19.125 19.1248Z"
                  fill="#DFDE02"
                />
                <path
                  d="M27.0937 11.1561C26.6155 11.1561 26.2968 10.9968 25.978 10.678L18.0094 2.70935C17.3719 2.07186 17.3719 1.11561 18.0094 0.478121C18.6469 -0.159373 19.6031 -0.159374 20.2406 0.478121L28.2093 8.4468C28.8468 9.08429 28.8468 10.0405 28.2093 10.678C27.8905 10.9968 27.5718 11.1561 27.0937 11.1561Z"
                  fill="#DFDE02"
                />
                <path
                  d="M27.0935 11.1561H1.59374C0.637494 11.1561 0 10.5186 0 9.56236C0 8.60612 0.637494 7.96863 1.59374 7.96863H27.0935C28.0497 7.96863 28.6872 8.60612 28.6872 9.56236C28.6872 10.5186 28.0497 11.1561 27.0935 11.1561Z"
                  fill="#DFDE02"
                />
              </svg>
            </a>
          </div>
        </section>

        <section data-aos="fade-up" className="py-10 container mx-auto px-5">
          <div className="relative flex flex-col gap-4 lg:px-0 xl:px-20">
            <div className="left-1/2 -translate-x-1/2 absolute max-w-xs lg:left-0 lg:-translate-x-0">
              <div className="flex items-center gap-5 mt-5">
                <span className="h-[1px] w-24 bg-primary"></span>
                <span>GROK CHAIN</span>
              </div>
              <h3 className="text-4xl font-mont font-bold mt-7">ROADMAP</h3>
            </div>

            <div className="hidden lg:flex justify-end lg:gap-14 xl:gap-28 2xl:gap-72">
              <div className="bg-box w-[297px] rounded-sm flex flex-col gap-3 relative p-4">
                <h4 className="text-4xl text-primary font-bold font-mont">
                  PHASE 2
                </h4>
                <ul className="flex flex-col gap-3 mt-5 ml-8">
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Community Building
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">Testnet Launch</span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Marketing Initiatives
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Community Cultivation
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Influencer Collaboration
                    </span>
                  </li>
                </ul>
              </div>
              <div className="bg-box w-[297px] rounded-sm flex flex-col gap-3 relative p-4">
                <h4 className="text-4xl text-primary font-bold font-mont">
                  PHASE 4
                </h4>
                <ul className="flex flex-col gap-3 mt-5 ml-8">
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">Market Expansion</span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Strategic Partnerships
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Diversification of Use Cases
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Scalability Enhancements
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Global Token Adoption
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="hidden lg:flex w-full">
              <img src={roadmapLine} alt="roadmap-line" />
            </div>
            <div className="hidden lg:flex lg:gap-14 xl:gap-28 2xl:gap-72">
              <div className="bg-box w-[297px] rounded-sm flex flex-col gap-3 relative p-4">
                <h4 className="text-4xl text-primary font-bold font-mont">
                  PHASE 1
                </h4>
                <ul className="flex flex-col gap-3 mt-5 ml-8">
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Community Building
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">Testnet Launch</span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Marketing Initiatives
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Community Cultivation
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Influencer Collaboration
                    </span>
                  </li>
                </ul>
              </div>
              <div className="bg-[url('./assets/images/roadmap-3.png')] bg-cover w-[297px] rounded-sm flex flex-col gap-3 relative p-4">
                <h4 className="text-4xl text-primary font-bold font-mont">
                  PHASE 3
                </h4>
                <ul className="flex flex-col gap-3 mt-5 ml-8">
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">Market Expansion</span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Strategic Partnerships
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Diversification of Use Cases
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Scalability Enhancements
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Global Token Adoption
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-40 flex flex-col justify-center items-center gap-5 lg:hidden">
              <div className="bg-box w-[297px] rounded-sm flex flex-col gap-3 relative p-4">
                <h4 className="text-2xl lg:text-4xl text-primary font-bold font-mont">
                  PHASE 1
                </h4>
                <ul className="flex flex-col gap-3 mt-5 ml-8">
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Community Building
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">Testnet Launch</span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Marketing Initiatives
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Community Cultivation
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Influencer Collaboration
                    </span>
                  </li>
                </ul>
              </div>
              <div className="bg-box w-[297px] rounded-sm flex flex-col gap-3 relative p-4">
                <h4 className="text-2xl lg:text-4xl text-primary font-bold font-mont">
                  PHASE 2
                </h4>
                <ul className="flex flex-col gap-3 mt-5 ml-8">
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Community Building
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">Testnet Launch</span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Marketing Initiatives
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Community Cultivation
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Influencer Collaboration
                    </span>
                  </li>
                </ul>
              </div>
              <div className="bg-[url('./assets/images/roadmap-3.png')] bg-cover w-[297px] rounded-sm flex flex-col gap-3 relative p-4">
                <h4 className="text-2xl lg:text-4xl text-primary font-bold font-mont">
                  PHASE 3
                </h4>
                <ul className="flex flex-col gap-3 mt-5 ml-8">
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">Market Expansion</span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Strategic Partnerships
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Diversification of Use Cases
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Scalability Enhancements
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Global Token Adoption
                    </span>
                  </li>
                </ul>
              </div>
              <div className="bg-box w-[297px] rounded-sm flex flex-col gap-3 relative p-4">
                <h4 className="text-2xl lg:text-4xl text-primary font-bold font-mont">
                  PHASE 4
                </h4>
                <ul className="flex flex-col gap-3 mt-5 ml-8">
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">Market Expansion</span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Strategic Partnerships
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Diversification of Use Cases
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Scalability Enhancements
                    </span>
                  </li>
                  <li className="flex gap-3 items-center">
                    <span className="w-3 h-3 rounded-full bg-primary"></span>
                    <span className="text-text text-sm">
                      Global Token Adoption
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20 px-5">
          <div className="bg-box text-center rounded-2xl px-5 lg:px-16 py-8 lg:py-12 max-w-5xl mx-auto">
            <div className="w-14 mx-auto">
              <img src={join} alt="user" />
            </div>
            <h3 className="text-2xl lg:text-4xl font-bold font-mont my-10">
              Join the{" "}
              <span className="text-primary">GrokChain Community.</span>
            </h3>
            <div className="flex justify-center">
              <Button text="Join Now" link="https://t.me/GrokChainTG" />
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-[#101010]" data-aos="fade-down">
        <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center justify-between gap-10 py-10 lg:py-20">
          <div className="max-w-[200px] lg:max-w-[290px]">
            <img src={footerLogo} alt="logo" />
          </div>
          <div className="flex flex-col items-center lg:flex-row gap-10 lg:gap-20">
            <div className="">
              <h4 className="text-center lg:text-left">Quick Links</h4>
              <ul className="flex flex-row lg:flex-col gap-5 text-sm  mt-3 lg:mt-8">
                <li>
                  <a
                    href="#about"
                    className="pb-1 border-b-2 border-transparent transition-all hover:border-primary hover:text-primary"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="#ecosystem"
                    className="pb-1 border-b-2 border-transparent transition-all hover:border-primary hover:text-primary"
                  >
                    Ecosystem
                  </a>
                </li>
                <li>
                  <a
                    href="#tokenomics"
                    className="pb-1 border-b-2 border-transparent transition-all hover:border-primary hover:text-primary"
                  >
                    Tokenomics
                  </a>
                </li>
              </ul>
            </div>
            <div className="">
              <h4 className="text-center lg:text-left">Subscribe</h4>
              <div className="flex items-center mt-3 lg:mt-8">
                <input
                  placeholder="Enter email address"
                  className="bg-[#3F4143] h-12 min-w-[240px] px-3 rounded-tl-full rounded-bl-full text-sm"
                />
                <span className="bg-primary cursor-pointer text-black h-12 flex items-center justify-center px-4 transition-all hover:opacity-75">
                  Subscribe
                </span>
              </div>
              <ul className="flex justify-between gap-6 mt-6">
                <li className="w-14">
                  <a
                    href="https://t.me/GrokChainTG"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={tg} alt="tg" />
                  </a>
                </li>
                <li className="w-14">
                  <a
                    href="https://twitter.com/GrokChainX"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={tw} alt="tg" />
                  </a>
                </li>
                <li className="w-14">
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <img src={dt} alt="tg" />
                  </a>
                </li>
                <li className="w-14">
                  <a href="#" target="_blank" rel="noopener noreferrer">
                    <img src={sc} alt="tg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="border-t-2 border-[#c3c3c3] text-center py-6 px-5">
          <span className="text-sm">
            GROK CHAIN © 2023, All Rights Reserved
          </span>
        </div>
      </footer>
    </div>
  );
}

export default App;
